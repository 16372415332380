/* eslint-disable no-empty-pattern */


export default {
  app: {
    state: {
      test: {},
      lang: localStorage.getItem('locale') || 'ru',
      translates: {},
      load: false,
      _save: [ 'lang', 'translates', ],
      lastActivity: '',
      isAccessBlock: false,
      timeRemaining: 0,
      companiesBlock: {},
      company: {},
      count: 0,
      startTransaction: {},
      transactionData: {},
      verificationType: null,
      user: {},
      verifyOtpCode: null,
      verifyBiometric: null, //* флаг который нужен будет для биометрии 
      paymentInfo: {},
      isShowBtnPayment: false
    },
    getters: {
      translates(state) {
        return state.translates
      },
      lang(state) {
        return state.lang
      },
      lastActivity(state) {
        return state.lastActivity
      },
      load(state) {
        return state.load
      },
      isAccessBlock(state) {
        return state.isAccessBlock
      },
      timeRemaining(state) {
        return state.timeRemaining
      },
      companiesBlock(state) {
        return state.companiesBlock
      }, 
      company(state) {
        return state.company
      }, 
      count(state) {
        return state.count
      },
      startTransaction(state) {
        return state.startTransaction
      }

    },
    mutations: {
      lang(state, value) {
        state.lang = value
      },
      translates(state, value) {
        state.translates = value
      },
      lastActivity(state, value) {
        state.lastActivity = value
      },
      load(state, value) {
        state.load = value
      },
      isAccessBlock(state, value) {
        state.isAccessBlock = value
      },
      timeRemaining(state, value) {
        state.timeRemaining = value
      },
      companiesBlock(state, value) {
        state.companiesBlock = value;
      },  
      isBlocked(state, value) {
        const blocked = state.companiesBlock.blockedCompanies.find((item) => item.companyId === value)
        state.isAccessBlock = blocked.isActive
        state.timeRemaining = (new Date(blocked.endAt).getTime() - new Date().getTime()) / 1000
      },
      setCompany(state, value) {
        state.company = value;
      },  
      setCount(state, value) {
        state.count = value     
      },
      setStartTransaction(state, value) {
        state.startTransaction = value
      },
      setTransactionData(state, value) {
        state.transactionData = value
      },
      setVerificationType(state, value) {
        state.verificationType = value
      },
      setUser(state, value) {
        state.user = value
      },
      setVerifyOtp(state, value) {
        state.verifyOtpCode = value
      },
      setVerifyBiometric(state, value) { //* флаг который нужен будет для биометрии 
        state.verifyBiometric = value
      },
      setPaymentInfo(state, value) {
        state.paymentInfo = value
      },
      setIsShowBtnPayment(state, value) {
        state.isShowBtnPayment = value
      }
    },
    actions: {
      updateUser({ state }) {
        return $api.auth.info()
          .with('company')
          .with('salaryStats')
          .with('monthTaken')
          .then((response) => {
            state.user = response.data.content.user
            $app.auth.user(response.data.content.user)
          })
          .catch((e) => {
            console.log(e);
            $app.ui.notify('Упс... Что-то пошло не так', 'danger')
          })
      },
      setLastActivity({ state, commit, }, value) {
        commit('lastActivity', value)
      },
      initLang({ state, dispatch, }) {
        localStorage.getItem('locale') ? dispatch('setLang', localStorage.getItem('locale')) : dispatch('setLang', state.lang)
      },
      setLang({ commit, }, value) {
        $app.api.config({
          headers: {
            'Locale': value,
          },
        })
        localStorage.setItem('locale', value)
        commit('lang', value)
        commit('load', true)
        $api.lang.items().then((response) => {
          commit('translates', response.data.content )
          $app.api.config({
            headers: {
              'Locale': localStorage.getItem('locale'),
            },
          })
        }).finally(() => {
          commit('load', false)
        })
      },
      getAccessBlock({ commit }, { userId, selectedCompany }) {
        commit('load', true)
        
        $api.accessBlock.get( userId, selectedCompany ).then((response) => {
          console.log('No active access blocks!');

          if (response.status === 200) {
            commit('isAccessBlock', false)
          }

        }).catch((error) => {
          if (error.response?.status === 423) {
            commit('companiesBlock', error.response.data )
            commit('isBlocked', selectedCompany )
          }
        }).finally(() => {
          commit('load', false)
        })
      },

      checkNeedApprove({ state, commit }, router) {
        const uuid = state.transactionData.transactionUuid

        $api.transactions.needApprove(uuid)
          .then((response) => {
            if (response.data.content) {
              return $n.t('5.5.0') 
            } else {
              return $api.transactions.complete({ transactionUuid: uuid })
            }
            
          })
          .then((completeResponse) => {

            if (completeResponse.data) {
              commit('setPaymentInfo', completeResponse.data)
              router.replace({ name: 'mobile.payment.success' });
            } else {
              $app.ui.notify(completeResponse, 'danger')
              router.replace({ name: 'mobile.index' })
            }

          })
          .catch((err) => {
            console.log(err);
            
          })
      },

      setSteps({ state, dispatch }, { router }) {
        const step = {
          OTP: () => {

            if (!state.verifyOtpCode) {
              console.log('verifyOtpCode не установлен, остаемся на текущей странице');
              router.replace({ name: 'mobile.otp-verify' });
            } else {
              console.log('verifyOtpCode установлен, выполняем checkNeedApprove');
              dispatch('checkNeedApprove', router);
            }

          },
          SMS_VERIFICATION: () => {

            if (!state.verifyOtpCode) {
              console.log('verifyOtpCode не установлен, остаемся на текущей странице');
              router.replace({ name: 'mobile.otp-verify' });
            } else {
              console.log('verifyOtpCode установлен, выполняем checkNeedApprove');
              dispatch('checkNeedApprove', router);
            }

          },
          BIOMETRIC: () => {

            if (state.verifyOtpCode) {
              router.replace({ name: 'mobile.biometric-verify' });
            } else {
              console.log('verifyOtpCode не установлен, остаемся на текущей странице');
              router.replace({ name: 'mobile.otp-verify' });
            }
            
          },
          APPROVE: () => {
            if (state.startTransaction.isLast) {
              dispatch('checkNeedApprove', router)
            }
          }
        }
        return step[state.startTransaction.stepName]()
      },

      getStep({ commit, state, dispatch }, { router }) {
        commit('load', true)

        if (state.startTransaction.isLast) {
          console.log('getStep', 1);

          dispatch('setSteps', { stepName: state.startTransaction.stepName, router })
          return 

        } else {
          console.log('getStep', 2);

          if (state.verifyOtpCode || state.verifyBiometric) {
            dispatch('setSteps', { stepName: response.data.data.stepName, router });
          }
          
          $api.transactions.step(state.startTransaction.id)
            .then((response) => {
              commit('setStartTransaction', response.data.data )
            
              dispatch('setSteps', { stepName: response.data.data.stepName, router });
            })
            .catch((error) => {
              throw error
            })
        }

      }
    },
    namespaced: true,
  },
}
